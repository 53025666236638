import React, { useState } from "react";
import { Link } from "react-scroll";
import { useRecoilValue } from "recoil";
import dataPortfolio from "../store";

function NavItem({ to, offset, click, name }) {
    return (
        <>
            <li className="nav__item">
                <Link
                    to={to}
                    spy={true}
                    offset={offset || 0}
                    className="nav__link"
                    onClick={() => click()}
                >
                    {name}
                </Link>
            </li>
        </>
    );
}

function Header() {
    const [open, setOpen] = useState(false);
    const { header } = useRecoilValue(dataPortfolio);

    const openNav = () => {
        setOpen(!open);
    };

    const { brand } = header;

    return (
        <>
            {header && (
                <header className="l-header">
                    <nav className="nav bd-grid">
                        <div
                            className="nav__toogle"
                            id="nav-toggle"
                            onClick={openNav}
                        >
                            <i className="bx bx-menu"></i>
                        </div>
                        <div>
                            <Link
                                to="home"
                                spy={true}
                                offset={-50}
                                className="nav__logo"
                            >
                                {brand}
                            </Link>
                        </div>
                        <div
                            className={open ? "nav__menu show" : "nav__menu"}
                            id="nav-menu"
                        >
                            <div
                                className="nav__close"
                                id="nav-close"
                                onClick={openNav}
                            >
                                <i className="bx bx-x"></i>
                            </div>
                            <ul className="nav__list">
                                <NavItem
                                    to="home"
                                    offset={-50}
                                    click={openNav}
                                    name="Home"
                                />
                                <NavItem
                                    to="about"
                                    click={openNav}
                                    name="About"
                                />
                                <NavItem
                                    to="skills"
                                    click={openNav}
                                    name="Skills"
                                />
                                <NavItem
                                    to="services"
                                    click={openNav}
                                    name="Services"
                                />
                                <NavItem
                                    to="works"
                                    click={openNav}
                                    name="Works"
                                />
                                <NavItem
                                    to="contact"
                                    click={openNav}
                                    name="Contact"
                                />
                            </ul>
                        </div>
                    </nav>
                </header>
            )}
        </>
    );
}

export default Header;
