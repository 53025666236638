import React, { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import About from './components/sections/About';
import Contact from './components/sections/Contact';
import Education from './components/sections/Education';
import Home from './components/sections/Home';
// import Project from './components/sections/Project';
import Services from './components/sections/Services';
import Skills from './components/sections/Skills';
import Works from './components/sections/Works';

function App() {
  return (
    <RecoilRoot>
      <Suspense fallback={<></>}>
        <Header />
        <main className='l-main'>
          <Home />
          <About />
          <Skills />
          <Education />
          <Services />
          {/* <Project /> */}
          <Works />
          <Contact />
        </main>
        <Footer />
      </Suspense>
    </RecoilRoot>
  );
}

export default App;
