import React from "react";
import { useRecoilValue } from "recoil";
import dataPortfolio from "../../store";

function Content({ icon, title, description }) {
    return (
        <div className="services__content">
            <i className={`${icon} services__icon`}></i>
            <h3 className="services__title">{title}</h3>
            <p className="services__description">{description}</p>
        </div>
    );
}

function Services() {
    const { services } = useRecoilValue(dataPortfolio);

    return (
        <>
            {services && (
                <section className="services section" id="services">
                    <span className="section-subtitle">What I Offer</span>
                    <h2 className="section-title">My Services</h2>

                    <div className="services__container bd-grid">
                        {services.map((service, index) => {
                            return (
                                <Content
                                    icon={service.icon}
                                    title={service.title}
                                    description={service.description}
                                    key={index}
                                />
                            );
                        })}
                    </div>
                </section>
            )}
        </>
    );
}

export default Services;
