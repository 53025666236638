import React from 'react';
import { Link } from 'react-scroll';
import { useRecoilValue } from 'recoil';
import dataPortfolio from '../../store';

function Social({ href, icon }) {
  return (
    <>
      <a
        href={href}
        className='home__social-link'
        target='_blank'
        rel='noopener noreferrer'
      >
        <i className={icon}></i>
      </a>
    </>
  );
}

function Home() {
  const { home } = useRecoilValue(dataPortfolio);

  const { img, title, profession, socials } = home;

  return (
    <>
      {home && (
        <section className='home' id='home'>
          <div className='home__container bd-grid'>
            <div className='home__data'>
              <div className='home__img'>
                <img src={img} alt='home' />
              </div>

              <h1 className='home__title'>{title}</h1>
              <span className='home__profession'>{profession}</span>

              <div className='home__social'>
                {socials.map(({ link, icon }, index) => {
                  return <Social href={link} icon={icon} key={index} />;
                })}
              </div>

              <Link
                to='about'
                spy={true}
                offset={0}
                className='button home__button'
              >
                Get Started
              </Link>
              {/* <a
                download
                href='/'
                className='button home__button'
                target='_blank'
                rel='noopener noreferrer'
              >
                
              </a> */}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Home;
