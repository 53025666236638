import React from "react";
import { useRecoilValue } from "recoil";
import dataPortfolio from "../../store";

function SkillData({ name, percent }) {
    return (
        <>
            <div className="skills__data">
                <span className="skills__name">{name}</span>
                <span className="skills__number">{percent}%</span>
                <span
                    className="skills__bar"
                    style={{ width: `${percent}%` }}
                ></span>
            </div>
        </>
    );
}

function Content({ title, skills }) {
    return (
        <>
            <div className="skills__content">
                <h3 className="skills__subtitle">{title}</h3>
                {skills.map((skill, index) => {
                    return (
                        <SkillData
                            name={skill.name}
                            percent={skill.percent}
                            key={index}
                        />
                    );
                })}
            </div>
        </>
    );
}

function Skills() {
    const { skills } = useRecoilValue(dataPortfolio);

    return (
        <>
            {skills && (
                <section className="skills section" id="skills">
                    <span className="section-subtitle">Why Choose Me</span>
                    <h2 className="section-title">My Expertise Area</h2>

                    <div className="skills__container bd-grid">
                        {skills.map((skill, index) => {
                            return (
                                <Content
                                    title={skill.field}
                                    skills={skill.list}
                                    key={index}
                                />
                            );
                        })}
                    </div>
                </section>
            )}
        </>
    );
}

export default Skills;
