import React from "react";
import { useRecoilValue } from "recoil";
import dataPortfolio from "../../store";

function Content({ years, organization, race, speciality }) {
    return (
        <div className="education__content">
            <div>
                <h3 className="education__year">{years}</h3>
                <span className="education__university">{organization}</span>
            </div>
            <div className="education__time">
                <span className="education__rounder"></span>
                <span className="education__line"></span>
            </div>
            <div>
                <h3 className="education__race">{race}</h3>
                <span className="education__speciality">{speciality}</span>
            </div>
        </div>
    );
}

function Education() {
    const { educations } = useRecoilValue(dataPortfolio);

    return (
        <>
            {educations && (
                <section className="education section">
                    <span className="section-subtitle">Qualification</span>
                    <h2 className="section-title">My Education</h2>

                    <div className="education__container bd-grid">
                        {educations.map((education, index) => {
                            return (
                                <Content
                                    years={education.years}
                                    organization={education.organization}
                                    race={education.race}
                                    speciality={education.speciality}
                                    key={index}
                                />
                            );
                        })}
                    </div>
                </section>
            )}
        </>
    );
}

export default Education;
