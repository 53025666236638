import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import dataPortfolio from '../store';

function Social({ href, icon }) {
  return (
    <>
      <a
        href={href}
        className='footer__link'
        target='_blank'
        rel='noopener noreferrer'
      >
        <i className={icon}></i>
      </a>
    </>
  );
}

function Footer() {
  const [year, setYear] = useState('');
  const { footer } = useRecoilValue(dataPortfolio);

  useEffect(() => {
    const date = new Date();
    setYear(date.getFullYear());
  }, [year]);

  //   const { title, description, socials, brand } = footer;
  const { socials, brand } = footer;

  return (
    <>
      {footer && (
        <footer className='footer section'>
          <div className='footer__container bd-grid'>
            {/* <h1 className="footer__title">{title}</h1>
                        <p className="footer__description">{description}</p> */}

            <div className='footer__social'>
              {socials.map(({ link, icon }, index) => {
                return <Social href={link} icon={icon} key={index} />;
              })}
            </div>

            <p className='footer__copy'>
              All right reserved by {brand} - {year}
            </p>
          </div>
        </footer>
      )}
    </>
  );
}

export default Footer;
