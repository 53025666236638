import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import dataPortfolio from '../../store';

function Info({ title, description }) {
  return (
    <div className='contact__info'>
      <h3 className='contact__subtitle'>{title}</h3>
      <span className='contact__text'>{description}</span>
    </div>
  );
}

function Contact() {
  const { contacts } = useRecoilValue(dataPortfolio);
  const [values, setValues] = useState({ name: '', email: '', message: '' });
  const onSubmit = (e) => {
    e.preventDefault();
    if (values.name && values.email && values.message) {
      sendMail();
    } else {
      alert("The fields can't be null");
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const sendMail = () => {
    const link =
      'mailto:divakrishnam@gmail.com' +
      '?cc=' +
      values.email +
      '&subject=' +
      encodeURIComponent(values.name) +
      '&body=' +
      encodeURIComponent(values.message);
    window.location.href = link;
  };

  return (
    <>
      {contacts && (
        <section className='contact section' id='contact'>
          <span className='section-subtitle'>Contact Me</span>
          <h2 className='section-title'>Get In Touch</h2>

          <div className='contact__container bd-grid'>
            <form onSubmit={onSubmit} className='contact__form'>
              <div className='contact__inputs'>
                <input
                  type='text'
                  name='name'
                  id=''
                  placeholder='Name'
                  className='contact__input'
                  onChange={onChange}
                />
                <input
                  type='email'
                  name='email'
                  id=''
                  placeholder='Email'
                  className='contact__input'
                  onChange={onChange}
                />
              </div>

              <textarea
                name='message'
                id=''
                cols='0'
                rows='10'
                placeholder='Message'
                className='contact__input'
                onChange={onChange}
              ></textarea>
              <input
                type='submit'
                value='Send Message'
                className='button contact__button'
              />
            </form>
            <div>
              {contacts.map((contact, index) => {
                return (
                  <Info
                    title={contact.title}
                    description={contact.description}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Contact;
