import React from 'react';
import { useRecoilValue } from 'recoil';
import dataPortfolio from '../../store';

function Content({ src, href, title }) {
  return (
    <div className='works__img'>
      <img src={src} alt='work' />
      <div className='works__data'>
        <a href={href} className='works__link' target='_blank' rel='noreferrer'>
          <i className='bx bx-link-alt'></i>
        </a>
        <span className='works__title'>{title}</span>
      </div>
    </div>
  );
}

function Works() {
  const { works } = useRecoilValue(dataPortfolio);

  return (
    <>
      {works && (
        <section className='works section' id='works'>
          <span className='section-subtitle'>My Portfolio</span>
          <h2 className='section-title'>Recent Works</h2>

          <div className='works__container bd-grid'>
            {works.map((work, index) => {
              return (
                <Content
                  src={work.src}
                  href={work.href}
                  title={work.title}
                  key={index}
                />
              );
            })}
          </div>
        </section>
      )}
    </>
  );
}

export default Works;
