import React from "react";
import { useRecoilValue } from "recoil";
import dataPortfolio from "../../store";

function Information({ icon, content }) {
    return (
        <div className="about__information-data">
            <i className={`${icon} about__information-icon`}></i>
            <span>{content}</span>
        </div>
    );
}

function Experience({ icon, subtitle, subsubtitle }) {
    return (
        <div className="about__information-data">
            <i className={`${icon} about__information-icon`}></i>
            <div>
                <span className="about__information-subtitle">{subtitle}</span>
                <span className="about__information-subtitle-small">
                    {subsubtitle}
                </span>
            </div>
        </div>
    );
}

function About() {
    const { about } = useRecoilValue(dataPortfolio);

    const {
        description,
        img,
        name,
        email,
        experience,
        project,
        support,
    } = about;

    return (
        <>
            {about && (
                <section className="about section" id="about">
                    <span className="section-subtitle">My Intro</span>
                    <h2 className="section-title">About Me</h2>
                    <div className="about__container bd-grid">
                        <div className="about__data">
                            <p className="about__description">{description}</p>
                            <img src={img} alt="about" className="about__img" />
                        </div>

                        <div>
                            <div className="about__information">
                                <h3 className="about__information-title">
                                    Information
                                </h3>
                                <Information
                                    icon={"bx bx-user"}
                                    content={name}
                                />
                                <Information
                                    icon={"bx bx-envelope"}
                                    content={email}
                                />
                            </div>

                            <div className="about__information">
                                <h3 className="about__information-title">
                                    Experience And Support
                                </h3>
                                <Experience
                                    icon={"bx bx-medal"}
                                    subtitle={`${experience} Job`}
                                    subsubtitle={"Experience"}
                                />
                                <Experience
                                    icon={"bx bx-briefcase"}
                                    subtitle={`${project} Projects`}
                                    subsubtitle={"Completed"}
                                />

                                <Experience
                                    icon={"bx bx-support"}
                                    subtitle={"Support"}
                                    subsubtitle={`Online ${support}`}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default About;
