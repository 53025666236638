import Axios from 'axios';

const { selector } = require('recoil');

const dataPortfolio = selector({
  key: 'data-portfolio',
  get: async () => {
    let data = {};
    await Axios.get('https://api.jsonbin.io/b/5fcf12dc65c249127ba4b7f5/5', {
      headers: {
        'secret-key':
          '$2b$10$A/pJy7zX.G2ekqJzFmHV5ev6sdRLvuBlu0VmkrpdmNG45HKVS2Yoq',
      },
    }).then((response) => {
      data = response.data;
    });

    return data;
  },
});

export default dataPortfolio;
